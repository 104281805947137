//paddings
.p-0
    padding: 0px !important

.p-5
    padding: 5px

.p-10
    padding: 10px

.p-15
    padding: 15px

.p-20
    padding: 20px

.p-25
    padding: 25px

.p-30
    padding: 30px

.p-35
    padding: 35px

.p-40
    padding: 40px

.p-45
    padding: 45px

.p-50
    padding: 50px

.p-55
    padding: 55px

.p-60
    padding: 60px

.p-l-5
    padding-left: 5px

.p-l-10
    padding-left: 10px

.p-l-15
    padding-left: 15px

.p-l-20
    padding-left: 20px

.p-l-25
    padding-left: 25px

.p-l-30
    padding-left: 30px

.p-l-35
    padding-left: 35px

.p-l-40
    padding-left: 40px

.p-l-45
    padding-left: 45px

.p-l-50
    padding-left: 50px

.p-l-55
    padding-left: 55px

.p-l-60
    padding-left: 60px

.p-r-5
    padding-right: 5px

.p-r-10
    padding-right: 10px

.p-r-15
    padding-right: 15px

.p-r-20
    padding-right: 20px

.p-r-25
    padding-right: 25px

.p-r-30
    padding-right: 30px

.p-r-35
    padding-right: 35px

.p-r-40
    padding-right: 40px

.p-r-45
    padding-right: 45px

.p-r-50
    padding-right: 50px

.p-r-55
    padding-right: 55px

.p-r-60
    padding-right: 60px

.p-t-5
    padding-top: 5px

.p-t-10
    padding-top: 10px

.p-t-15
    padding-top: 15px

.p-t-20
    padding-top: 20px

.p-t-25
    padding-top: 25px

.p-t-30
    padding-top: 30px

.p-t-35
    padding-top: 35px

.p-t-40
    padding-top: 40px

.p-t-45
    padding-top: 45px

.p-t-50
    padding-top: 50px

.p-t-55
    padding-top: 55px

.p-t-60
    padding-top: 60px

.p-b-5
    padding-bottom: 5px

.p-b-10
    padding-bottom: 10px

.p-b-15
    padding-bottom: 15px

.p-b-20
    padding-bottom: 20px

.p-b-25
    padding-bottom: 25px

.p-b-30
    padding-bottom: 30px

.p-b-35
    padding-bottom: 35px

.p-b-40
    padding-bottom: 40px

.p-b-45
    padding-bottom: 45px

.p-b-50
    padding-bottom: 50px

.p-b-55
    padding-bottom: 55px

.p-b-60
    padding-bottom: 60px

// margins

.m-5
    margin: 5px

.m-10
    margin: 10px

.m-15
    margin: 15px

.m-20
    margin: 20px

.m-25
    margin: 25px

.m-30
    margin: 30px

.m-35
    margin: 35px

.m-40
    margin: 40px

.m-45
    margin: 45px

.m-50
    margin: 50px

.m-55
    margin: 55px

.m-60
    margin: 60px

.m-l-5
    margin-left: 5px

.m-l-10
    margin-left: 10px

.m-l-15
    margin-left: 15px

.m-l-20
    margin-left: 20px

.m-l-25
    margin-left: 25px

.m-l-30
    margin-left: 30px

.m-l-35
    margin-left: 35px

.m-l-40
    margin-left: 40px

.m-l-45
    margin-left: 45px

.m-l-50
    margin-left: 50px

.m-l-55
    margin-left: 55px

.m-l-60
    margin-left: 60px

.m-r-5
    margin-right: 5px

.m-r-10
    margin-right: 10px

.m-r-15
    margin-right: 15px

.m-r-20
    margin-right: 20px

.m-r-25
    margin-right: 25px

.m-r-30
    margin-right: 30px

.m-r-35
    margin-right: 35px

.m-r-40
    margin-right: 40px

.m-r-45
    margin-right: 45px

.m-r-50
    margin-right: 50px

.m-r-55
    margin-right: 55px

.m-r-60
    margin-right: 60px

.m-t-5
    margin-top: 5px

.m-t-10
    margin-top: 10px

.m-t-15
    margin-top: 15px

.m-t-20
    margin-top: 20px

.m-t-25
    margin-top: 25px

.m-t-30
    margin-top: 30px

.m-t-35
    margin-top: 35px

.m-t-40
    margin-top: 40px

.m-t-45
    margin-top: 45px

.m-t-50
    margin-top: 50px

.m-t-55
    margin-top: 55px

.m-t-60
    margin-top: 60px

.m-b-5
    margin-bottom: 5px

.m-b-10
    margin-bottom: 10px

.m-b-15
    margin-bottom: 15px

.m-b-20
    margin-bottom: 20px

.m-b-25
    margin-bottom: 25px

.m-b-30
    margin-bottom: 30px

.m-b-35
    margin-bottom: 35px

.m-b-40
    margin-bottom: 40px

.m-b-45
    margin-bottom: 45px

.m-b-50
    margin-bottom: 50px

.m-b-55
    margin-bottom: 55px

.m-b-60
    margin-bottom: 60px

.m-b-65
    margin-bottom: 65px

.m-b-70
    margin-bottom: 70px

.m-b-75
    margin-bottom: 75px

.m-b-80
    margin-bottom: 80px

.m-b-85
    margin-bottom: 85px

.m-b-90
    margin-bottom: 90px

.bottom
    bottom: 0px

.top
    top: 0px

.m-l-auto
    margin-left: auto

.m-r-auto
    margin-right: auto

.m-t-auto
    margin-top: auto

.m-b-auto
    margin-bottom: auto
