.card
  margin: 0 20px
  &.m-auto
    margin: auto

.cardedit
  margin-top: -15px !important
  margin-left: 25px !important

mat-card-title
  color: $primary-color

mat-card
  border-radius: 16px

.bg-p
  background-color: $primary-color

.bg-s
  background-color: $secondary-color

.bg-t
  background-color: $teriary-color

.bg-pa
  background-color: $primaryaccent-color

.bg-sa
  background-color: $secondaryaccent-color

.bg-ta
  background-color: $teriaryaccent-color

.xs-card
  background-color: white
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.xs-card-t
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.btn-card-t
  width: 140px
  padding: 5px 10px
  border: 0.5px solid rgba(0, 0, 0, 0.5)
  border-radius: 5px

.orderBtn-card-t
  width: 170px
  padding: 5px 10px
  border: 0.5px solid rgba(0, 0, 0, 0.5)
  border-radius: 5px
  background-color: white
  text-transform: uppercase !important
  span
    font-size: 12px !important

.back
  width: auto !important
  margin-right: auto !important

.pMargins
  p
    margin: 0px

.btn-total-t
  width: auto
  color: white
  background-color: #007E92
  padding: 10px

.s-card-t
  padding: 10px
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.ss-card-t
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.s-card
  padding: 10px
  background-color: white
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.b-card
  padding: 40px
  background-color: white
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.l-card
  padding: 60px
  background-color: white
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 5px

.name-display
  padding: 30px
  background-color: white
  border: 0.5px solid rgba(0, 0, 0, 0.12)
  border-radius: 10px

.tradeCard
  background: #FFFFFF
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15)
  border-radius: 5px

.tradeCardNoShadow
  background: #FFFFFF
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15)
  border-radius: 5px

.manageProduct
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-areas: "area1 area1 area1" "area2 area2 area2" "area6 area4 area4"
  grid-column-gap: 20px
  grid-row-gap: 20px

.manageProductssLine1
  grid-area: area1

.productDescription
  grid-area: area2

.uploadImage
  grid-area: area3

.manageProductsLine3
  grid-area: area4

.addProduct
  grid-area: area5

.partnerMarketCards
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) )
  grid-column-gap: 10px
  grid-row-gap: 10px

.partnerCard
  height: auto
  border-radius: 5px
  width: 250px
  h3
    margin-top: 10px !important
    margin-bottom: 10px !important
    div > img
      width: 80%
      padding-top: 20px !important
      padding-bottom: 20px !important

.imgHolder
  object-fit: cover
  .notAvailableImage
    object-fit: contain
    img
      height: 50px
      width: 50px
      object-fit: contain
