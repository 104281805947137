//buttons
.btn
    height: 40px
    margin: 10px 10px
    min-width: 120px
    font-size: 1em
    border-radius: 8px
    &.ml1
        margin-left: 1em //to be picked up as ml1 is not working

.smallbtn
    height: 30px
    margin: 1em 10px 5px 10px
    min-width: 120px
    line-height: normal
    span
        font-size: 1em

.primaryBtn
    background-color: $primary-color
    color: $lightText
    border: none
    cursor: pointer

.primaryBtn:hover
    background-color: $primary-color
    color: $lightText
    cursor: pointer

.fileUploadM-l
    margin: 0 0 0 14px

.secondaryBtn
    background-color: $secondary-color
    color: $lightText
    cursor: pointer
    border: 1px solid $teriary-color

.tertiaryBtn
    background-color: $newColor
    color: $lightText
    cursor: pointer
    border: 1px solid $teriary-color

.tableBtn
    background-color: transparent
    color: $darkText
    border: 1px solid $teriary-color
    justify-content: left
    align-items: left
    text-align: left
    padding: 10px 20px
    margin: 5px 5px
    font-size: 1em
    cursor: pointer
    border-radius: 8px
    transition: all 0.3s ease

.tableBtn:hover
    background-color: black
    color: white
    cursor: pointer
    border: 1px solid white
    transition: all 0.3s ease

.btn-spacing.mat-flat-button, .btn-spacing.mat-stroked-button
    margin-top: 1em

.textOnly
    background-color: transparent
    color: $darkText
    border: none
    display: flex
    justify-content: center
    margin: 0 auto
    cursor: pointer

.breadCrumb
    background-color: transparent
    color: $darkText
    border: none
    display: flex
    justify-content: center
    margin: 0 5px
    cursor: pointer
    padding: 0 5px !important
    span
        padding: 0px !important
        font-size: 12px !important

.backBtn
    background-color: transparent
    color: $darkText
    border: none
    display: flex
    cursor: pointer

label.chooseFileBtn
    position: absolute !important
    left: 0px
    width: 65px
    min-width: 65px
    padding: 8px 16px
    margin: -5px 0 0 10px
    font-size: 11px
    height: 16px
    cursor: pointer

.cancelBtn
    background-color: white
    color: black
    cursor: pointer
    border: 1px solid #333333 !important

.manageButtons
    button
        color: red
        font-size: 14px
        background-color: rgba(0,0,0,0)
        border: none !important
        border-radius: 2px
        padding: 5px 10px
        font-weight: bold
        p
            color: red !important
    button:disabled
        color: gray

.productButtons
    button
        font-size: 20px !important
        line-height: 23px !important
        text-align: right !important
        color: #E60000
        background-color: rgba(0,0,0,0)
        border: none !important
        border-radius: 2px
        padding: 5px 10px
        p
            color: #E60000
    button:disabled
        color: gray

.userButtons
    button
        color: red
        font-size: 14px
        background-color: rgba(0,0,0,0)
        border: none !important
        border-radius: 2px
        padding: 5px 10px
        font-weight: light
    button:disabled
        color: gray

.orderActionButtons
    button
        font-size: 14px
        background-color: rgba(0,0,0,0)
        border: 1px !important
        border-radius: 2px
        padding: 5px 10px
        font-weight: light

    button:disabled
        color: gray
        opacity: 0.4 !important

.loadMore
    font-size: 15px !important
    line-height: 23px !important
    text-align: center !important
    width: 100%
    background-color: rgba(0,0,0,0)
    border: none !important
    border-radius: 2px
    padding: 5px 10px

.downloadAllBtn
    margin: 5px !important
    border-radius: 20px !important
    box-shadow: 1px 5px 3px lightGray
    padding: 10px 20px !important

.clickedIdType
    background: #1B1730 !important
    color: white
    p
        color: white
