.mat-icon-button
  padding: 0
  min-width: 0
  flex-shrink: 0
  border-radius: 50%
  line-height: inherit

.menu-tree-invisible
  display: none

.mat-tree
  height: 100%
  padding: 10px 0 10px 5px
  border-right: 1px solid $Lightgray

.menu-tree ul,
.menu-tree li
  margin-top: 0
  margin-bottom: 0
  list-style-type: none

.menu-tree span
  font-size: 13px

.tree-icon
  width: 15px

.mat-tree-node
  min-height: auto !important

ul
  padding-inline-start: 20px

li img
  width: 20px
  float: left
  padding-right: 10px

li button img
  width: 15px
  height: 15px
  padding-right: 0
  margin-top: 2px
  margin-left: 3px

#node-content
  width: 100%
  padding: 1px 0 0 0
  border-top-left-radius: 5px
  border-bottom-left-radius: 5px
  p
    margin: 0
    margin-top: 3px
    font-size: 14px
  &:hover
    background: rgba(250,250,250,1)
    cursor: default

.selected-node
  background: #eeeeee

svg
  color: #aaa
  margin: 0 4px 2px 0
