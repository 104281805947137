.mat-drawer-content .mat-tab-body-content
    overflow: auto !important

    mat-icon: hover
    cursor: pointer
    opacity: 0.7
    transition: all 0.3s ease

mat-icon
    transition: all 0.3s ease

.mat-tab-body .mat-tab-body-active
    overflow-y: auto !important

tr:nth-child(even)
    background-color: #f9f9f9

th
    font-size: 15px !important

td > button
    margin-left: auto !important

// Fonts and text
@font-face
    font-family: "VodacomFont"
    src: url("/assets/font/VodafoneRg-Regular.ttf") format("truetype")
    src: url("/assets/font/VodafoneRg-Regular.eot") format("embedded-opentype")
    src: url("/assets/font/VodafoneRg-Regular.woff") format("woff")
@font-face
    font-family: "VodacomFont-Light"
    src: url("/assets/font/VodafoneLt-Regular.ttf") format("truetype")
    src: url("/assets/font/VodafoneLt-Regular.eot") format("embedded-opentype")
    src: url("/assets/font/VodafoneLt-Regular.woff") format("woff")
@font-face
    font-family: "VodacomFont-Bold"
    src: url("/assets/font/VodafoneRg-Bold.ttf") format("truetype")
    src: url("/assets/font/VodafoneRg-Bold.eot") format("embedded-opentype")
    src: url("/assets/font/VodafoneRg-Bold.woff") format("woff")

.bg
    height: 100vh
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    -webkit-filter: blur(2px)
    filter: blur(2px)

body, p, span
    font-size: $fontsize

.sub-text
    font-size: 10px

.title
    font-size: 40px !important
    line-height: 47px !important
    text-align: justify !important
    letter-spacing: 1.5px !important
    text-transform: capitalize !important
    font-weight: 700 !important
    font-style: normal

.heading
    color: $primary-color !important
    font-size: 24px !important
    line-height: 28px !important
    letter-spacing: 1px !important
    color: #333333 !important
    font-weight: bold !important

.subHeading
    font-size: 18px !important
    line-height: 21px !important
    letter-spacing: 1px !important
    color: #333333 !important
    font-weight: 700 !important
    font-style: normal !important
    line-height: 28px !important
    letter-spacing: 1px !important
    color: #333333 !important

.tpInfoP
    font-size: 20px !important
    line-height: 23px !important
    letter-spacing: 0.5px !important
    color: #333333 !important
    font-weight: bold !important
    margin: 0px

.p-l
    font-style: normal !important
    font-weight: normal !important
    font-size: 14px !important
    line-height: 15px !important
    letter-spacing: 0.5px !important

.totalSubHeading
    font-style: normal !important
    font-weight: bold !important
    font-size: 14px !important
    line-height: 15px !important
    text-align: justify !important
    letter-spacing: 0.5px !important

.statusHeader
    font-size: 16px !important
    line-height: 19px !important
    letter-spacing: 1.5px !important
    font-weight: bold !important
    margin-bottom: 15px !important

.paragraph
    font-size: 16px !important
    line-height: 19px !important
    margin-bottom: 15px !important
    text-align: justify !important

.listP
    font-size: 16px !important
    line-height: 19px !important
    margin-bottom: 15px !important
    text-align: justify !important
    li
        padding: 5px

.p-b
    font-size: 16px !important
    line-height: 19px !important
    font-weight: bold !important

.p-t
    font-size: 16px !important
    line-height: 19px !important
.imageLine
    height: 40px
    margin-left: 5px !important
    margin-right: 5px !important

.p-status
    margin-bottom: 25px !important
    font-style: normal !important
    font-weight: normal !important
    font-size: 14px !important
    line-height: 15px !important
    text-align: justify !important
    letter-spacing: 0.5px !important

.totalOrderStatus
    margin-bottom: 15px !important
    font-style: normal !important
    font-weight: normal !important
    font-size: 14px !important
    line-height: 15px !important
    text-align: justify !important
    letter-spacing: 0.5px !important
.header
    color: $primary-color
    font-size: 24px
    line-height: 28px
    letter-spacing: 1px
    color: #333333

.paragraphTexts
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 24px

/* or 24px */

.text-white
    color: white

.textWhite
    color: $lightText

.nowrap
    white-space: nowrap !important

// general

body, p, h1, h2, h3, .mat-button, .mat-tab-group, input, select, textarea, .mat-input-element
    font-family: "VodacomFont"
    overflow: hidden
    text-align: left

html, body
    overflow: auto
    height: 100%

body, p, h1, h2, h3, .mat-button
    font-family: "VodacomFont", VodacomFont
span
    font-size: 16px
body
    margin: 0
    background: $LowGray

.background-white
    background: $White

h1, h2, h3, h4
    margin: 0px

.clickme
    cursor: pointer

.container
    width: 90%
    margin: auto

.centeredContainer
    width: 50%
    margin: auto

.left
    float: left

.right
    float: right

.full-height
    height: calc(100vh - 65px)

.full-width
    width: 100%

.width-2
    width: 2%

.width-5
    width: 5%

.width-10
    width: 10%

.width-20
    width: 20%

.width-30
    width: 30%

.width-35
    width: 35%

.width-40
    width: 40%

.width-60
    width: 60%

.width-70
    width: 70%

.width-75
    width: 75%

.width-80
    width: 80%

.width-85
    width: 85%

.width-90
    width: 90%

.width-100
    width: 100%

.height
    height: 100%
    width: 100%
    position: fixed

.height-10
    height: 10%

.height-20
    height: 20%

.height-30
    height: 30%

.height-60
    height: 60%

.height-70
    height: 70%

.height-80
    height: 80%

.height-90
    height: 90%

.height-100
    height: 100%

.legalNoticeHeight
    height: 300px !important
    overflow-y: scroll
    overflow-x: hidden

$w-and-h-values: "width" width,"height" height

@each $j in $w-and-h-values
    @for $i from 0 to 101
        .#{nth($j, 2)}-#{$i}
            #{nth($j, 2)}: $i * 1%

.half-width
    width: 50%

.form
    min-width: 150px
    max-width: 600px
    width: 100%

.error
    color: $primary-color

.borderRadius
    border-radius: 10px
// icons
.tiny-icon
    width: 14px
    height: 14px
.small-icon
    width: 20px
    height: 20px
.info-m1
    margin: 1.2em
.icon
    width: 20px
    height: 20px
.over
    position: relative
    left: -10px
.shiftDown
    top: 4px
    position: relative

// display
.hidden
    display: none

.show
    display: block

.inlineflex
    display: inline-flex

.flex
    display: flex
    flex-direction: column

//margins
.m0
    margin: 0
.mt0
    margin-top: 0em
.m1
    margin: 1em
.mt1
    margin-top: 1em
.mb1
    margin-bottom: 1em
.mb2
    margin-bottom: 2em
.mh1
    margin: 0em 1em
.mr1
    margin-right: 1em
.mr2
    margin-right: 2em
.mr5
    margin-right: 5em
.ml1
    margin-left: 1em
.ml2
    margin-left: 2em
.ml3
    margin-left: 3em
.ml4
    margin-left: 4em
.m-auto
    margin: auto
// padding
.p0
    padding: 0
.pt0
    padding-top: 0
.ptSmall
    padding-top: 0.5em
.psmall
    padding: 0.5em
.p1
    padding: 1em
.p2
    padding: 2em
.pr1
    padding-right: 1em
.pl1
    padding-left: 1em
//  Progress Spinner
.mat-progress-spinner circle, .mat-spinner circle
    stroke: $primary-color

.largeSpinner
    height: 100px
    width: 100px

.medium-spinner
    height: 50px
    width: 50px

.small-spinner
    height: 25px
    width: 25px

.spinnerPosition
    position: relative
    margin: 20px
    text-align: center

    // Summary items

.spinnerPositionPower
    position: relative
    margin: 0px
    top: 10px
    text-align: left

.summary-item
    display: table-row
    line-height: 1.6em

.summary-label
    display: table-cell
    max-width: 220px
    min-width: 170px
    width: 200px

.summary-value
    display: table-cell

.link
    text-decoration: underline
    // color: $primaryaccent-color
    cursor: pointer

// Table
.TableGray
    background-color: $Gray
    .mat-sort-header-button, .mat-sort-header-arrow
        color: white
table
    //max-height: 300px
    .activityLogTables
        table
            max-height: auto !important

.mat-paginator-sticky
    bottom: 0px
    position: sticky
    z-index: 10

td.mat-cell, td.mat-footer-cell, th.mat-header-cell
    text-align: left

//auto center horizontally
.center-item
    margin-left: auto
    margin-right: auto

// tabs
//.mat-tab-body.mat-tab-body-active
//    max-height: 83vh
//    height: 80vh
//    background: white
//    padding-top: 1em

// ::ng-deep .mat-tab-body.mat-tab-body-active
//     max-height: 83vh 
//     background: $White

// hides the scroll bars and stops them from flickering on tabs
.mat-tab-body-content
    // height: calc(100% - 65px)  
    overflow-x: hidden

mat-progress-bar.good.mat-progress-bar.mat-primary 
    div.mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element::after
        background: green

mat-progress-bar.warn.mat-progress-bar.mat-primary 
    div.mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element::after
        background: orange

mat-progress-bar.danger.mat-progress-bar.mat-primary 
    div.mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element::after
        background: red

.redColor
    color: red

.notification-success
    background: #428600

.notification-error
    background: red

.mat-horizontal-stepper-header
    height: 52px
    margin-top: 1em

.mat-stepper-horizontal-line
    margin-top: 1em

.note
    border: 1px #333 solid
    align-self: center

.note-error
    border: 1px #e60000 solid
    align-self: center

//  8. Flex
//  --------------
//  Display
.fx-box  ,.mat-card-actions.fx-box
    display: flex

//  Direction
.fx-row
    flex-direction: row

.fx-row-reverse
    flex-direction: row-reverse

.fx-col
    flex-direction: column

.fx-col-reverse
    flex-direction: column-reverse

//  Wrap
.fx-wrap
    flex-wrap: wrap

.fx-nowrap
    flex-wrap: nowrap

.fx-wrap-reverse
    flex-wrap: wrap-reverse

.scroll
    overflow: auto !important

//  Justify Content
.fx-jc-center
    justify-content: center

.fx-jc-start
    justify-content: flex-start

.fx-jc-end
    justify-content: flex-end

.fx-jc-space-between
    justify-content: space-between

.fx-jc-space-around
    justify-content: space-around

.fx-jc-space-evenly
    justify-content: space-evenly

//  Align Items
.fx-ai-center
    align-items: center

.fx-ai-stretch
    align-items: stretch

.fx-ai-start
    align-items: flex-start

.fx-ai-end
    align-items: flex-end

.fx-ai-baseline
    align-items: baseline

//  Align Self
.fx-as-auto
    align-self: auto

.fx-as-center
    align-self: center

.fx-as-stretch
    align-self: stretch

.fx-as-flex-start
    align-self: flex-start

.fx-as-flex-end
    align-self: flex-end

.fx-as-baseline
    align-self: baseline

//  Align Content
.fx-ac-stretch
    justify-content: stretch

.fx-ac-center
    justify-content: center

.fx-ac-start
    align-content: flex-start

.fx-ac-end
    align-content: flex-end

.fx-ac-space-between
    align-content: space-between

.fx-ac-space-around
    align-content: space-around

.fx-items-center
    justify-content: center
    align-items: center

//border lines
.bl-t
    border-top: 1px solid rgba(0, 0, 0, 0.52) !important
.bl-b
    border-bottom: 1px solid rgba(0, 0, 0, 0.52) !important
.bl-l
    border-left: 1px solid rgba(0, 0, 0, 0.52) !important
.bl-r
    border-right: 1px solid rgba(0, 0, 0, 0.52) !important

.bl-tt
    border-top: 3px solid rgba(0, 0, 0, 0.52) !important
.bl-bt
    border-bottom: 3px solid rgba(0, 0, 0, 0.52) !important
.bl-lt
    border-left: 3px solid rgba(0, 0, 0, 0.52) !important
.bl-rt
    border-right: 3px solid rgba(0, 0, 0, 0.52) !important

.bl-t-l
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important
.bl-b-l
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important
.bl-l-l
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important
.bl-r-l
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important

.bl-t-w
    border-top: 1px solid rgba(255, 255, 255, 0.52) !important
.bl-b-w
    border-bottom: 1px solid rgba(255, 255, 255, 0.52) !important
.bl-l-w
    border-left: 1px solid rgba(255, 255, 255, 0.52) !important
.bl-r-w
    border-right: 1px solid rgba(255, 255, 255, 0.52) !important

//border lines
.border
    border: 1px solid rgba(0, 0, 0, 0.12) !important

.fw-100
    font-weight: 100 !important

.fw-200
    font-weight: 200 !important

.fw-300
    font-weight: 300 !important

.fw-400
    font-weight: 400 !important

.fw-500
    font-weight: 500 !important

.fw-600
    font-weight: 600 !important

.fw-700
    font-weight: 700 !important

.fw-800
    font-weight: 800 !important

.fw-900
    font-weight: 900 !important

.m-l-auto
    margin-left: auto

.m-r-auto
    margin-right: auto

.m-l-autoI
    margin-left: auto !important

.m-r-autoI
    margin-right: auto !important

.bg-vod
    background-color: #e60000

.round
    border-radius: 200px !important

.roundAddress
    border-radius: 20px !important

.fieldColor
    background-color: #F7F6F9

.whiteBG
    background-color: white !important

.grayBG
    background-color: #F3F3F3 !important

.redText
    color: #e60000 !important

.blueText
    color: #00B0CA !important

.selectedItem
    cursor: pointer
    transition: all ease 0.3s

.fade
    opacity: 0.4

.hide
    display: none

.imgHolder
    object-fit: cover
    .notAvailableImage
        object-fit: cover
    img
        height: 50px
        width: 50px
        object-fit: contain

.parnetListImages
    flex: 1 0 21%

.orderCountNo
    font-size: 40px !important

.orderCountTxt
    font-size: 25px !important

.noOverFlow
    overflow: hidden !important

.tradingPartnerItems
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 10px
    grid-row-gap: 10px
    img
        width: 120px !important

.diabledTopBtn
    pointer-events: none
    color: gray !important

.dropZone
    height: 50vh !important
    transition: all 0.3s ease

.dragStarted
    border: $newColor 5px solid
    transition: all 0.3s ease

.dragText
    font-size: 28px
    color: #CCCCCC

.descriptionImage
    width: 30px
    height: 30px
    border-radius: 30px
    background-color: gray

.partnerMarketplaceArea
    .manageSuppliers
        grid-column: 1 / span 2
        padding: 0px 40px
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-column-gap: 20px
        grid-row-gap: 10px
        padding: 20px 0px

.redButton
    font-size: 16px
    line-height: 19px
    text-align: center
    letter-spacing: 0.5px
    text-transform: uppercase
    color: #E60000

.partnerMarketImg
    img
        width: 150px
        height: 150px

.pmAddress
    h4
        margin: 0px
        font-weight: 500

.marketTradeDetails
    p
        margin: 0px
    h3
        margin: 0px

.pmMap
    width: 400px
    height: 400px

.loadBar
    background-color: gray
    border-radius: 100px

.mat-slide-1
    .mat-slider-thumb,.mat-slider-track-fill,.mat-slider-thumb-label
        background-color: #00B0CA !important

.outline
    background: #FFFFFF
    border: 1px solid #D7D7D7
    box-sizing: border-box
    border-radius: 6px
    margin: 0px
    .mat-form-field-wrapper
        padding-bottom: 0px
    .mat-form-field-infix
        padding-top: 0px

.selectedMonth
    color: #007E92
    font-weight: 600 !important

.selectedRow
    background-color: #007E92
    color: white

.noMargin
    margin: 0 !important

.min-width
    min-width: 500px
    max-width: 700px

.artmin-width
    min-width: 100px
    max-width: 700px

.artBG
    background-color: $darkNavBG

.loginCol
    color: #9E97AA !important
    line-height: 30px

.headingTxt
    font-size: 30px !important
    line-height: 30px !important

.subheadingTxt
    font-size: 20px !important

.logintxt
    color: #6A6573

.bottomLinks
    min-height: auto
    justify-content: space-around
    display: flex

.bottomLinks > a
    color: #00B0CA
    text-align: left

.height
    height: 100%
    width: 100%
    position: fixed

.mg0
    margin-bottom: 0px !important
    padding-bottom: 0px !important
    margin-top: 0px !important
    padding-top: 0px !important
    .mat-form-field-wrapper
        margin-bottom: 0px !important
        padding-bottom: 0px !important
        margin-top: 0px !important
        padding-top: 0px !important
    .mg0 > *
        margin-bottom: 0px !important
        padding-bottom: 0px !important
        margin-top: 0px !important
        padding-top: 0px !important

.passwordConditions
    padding-top: 50px

.passwordRules
    .indicator
        border-radius: 10px
        width: 10px !important
        height: 10px !important
        color: red
        background: gray

.bgRed
    background: red !important

.loginHeading
    font-size: 30px !important
    line-height: 30px !important

.loginBg
    background-image: url("/assets/images/loginBg.png")
    background-repeat: no-repeat
    background-size: auto 100%

.lightGray
    color: $Lightgray

.whiteTxt
    color: white

.whiteBg
    color: white

.helpTopBG
    background-color: #1A1029

.alignLeft
    text-align: left !important

.alignRight
    text-align: right !important

.alignCenter
    text-align: center !important

.invoicedStyling
    th
        font-size: 15px !important
    td > p
        font-size: 10px !important
    *
        overflow: visible !important

mat-error
        margin-top: 10px !important
        text-align: right !important

.mat-form-field-infix
    border-top: 0 solid !important
    padding-bottom: 20px !important

.mat-form-field-wrapper
    padding-bottom: 0px !important

.invoice
    & mat-form-field
        height: 40px !important
    .mat-form-field-infix
        padding: .9em 0

.blb
    border-bottom: solid gray 3px

.blb-l
    border-bottom: solid lightGray 1px

.blt
    border-top: solid gray 3px

.blt-l
    border-top: solid lightGray 1px

.edit
    color: #00B0CA !important
.remove
    color: #E60000

.linkedRetailersImage
    width: 110px !important
    height: auto !important

.scrollTable
    height: 200px !important
    overflow-x: hidden
    overflow-y: auto

.scrollLinkTable
    min-height: 100px !important
    max-height: 500px !important
    overflow-x: hidden
    overflow-y: auto

.grayBanner
    background: #F9F9F9

.pointer
    cursor: pointer

.helpConfirm
    width: 200px
    p
        color: gray
        text-align: center
        font-weight: 700
        font-size: 12px

.smallSquare
    width: 10px !important
    height: 10px !important

.centerTextHeight
    display: inline-grid
    justify-items: center
    align-items: center !important
