//checkboxes
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background
    background-color: $teriary-color

// hide number spinners on inputs that are numbers
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

input.header
    font-size: 0.85em
    width: 100%
    margin: 0.15em

.large
    width: 250px

.xlarge
    width: 300px

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick
    color: $secondaryaccent-color

.mat-form-field
    display: block
    margin-bottom: 0.3em // extra padding for errors and floated text to look better
