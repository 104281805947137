/* You can add global styles to this file, and also import other style files */

@import "./sass/variables.sass"
@import "./sass/buttons.sass"
@import "./sass/general.sass"
@import "./sass/modal.sass"
@import "./sass/scrollbar.sass"
@import "./sass/inputs.sass"
@import "./sass/snackbar.sass"
@import "./sass/card.sass"
@import "./sass/tree.sass"
@import "./sass/graph.sass"
@import "./sass/overwrites.sass"
@import "./sass/responsive.sass"
@import "./sass/padAndMargins.sass"

*
    bordser: solid red 1px
    

.loader .mat-progress-spinner circle, .mat-spinner circle 
    stroke: $darkNavBG


.mat-tab-label
    width: 100% !important
    transition: all 0.3s ease

.mat-tab-label[aria-selected="true"]
    color: red
    opacity: 1 !important
    font-size: 19px
    transition: all 0.3s ease

.mat-ink-bar
    background-color: red !important
    height: 3px !important

.mat-list-item-content, mat-list-item-content
    padding: 0px 0px 0px 16px !important
    padding-right: 16 !important

.mat-form-field-underline
    display: none !important

.mat-list-item-content
    padding: 0px 0px 0px 16px !important

.mat-dsialog-container
    height: auto !important
tr:nth-child(2n)
    background-color: white !important

.mat-body p, .mat-body-1 p, .mat-typography p 
    //margin: 0px !important

th.mat-header-cell, td.mat-cell, td.mat-footer-cell
    width: 100px !important
    &.manageUsers
        width: auto !important
    *
        overflow: visible

.rmPB
    padding-bottom: 0px !important

.mat-snack-bar-container 
 margin-left: -80% !important

.mat-flat-button, .btn
    height: 44px
    line-height: 44px !important
    
th, .mat-header-cell
    padding: 0 20px 0 10px  !important

td, .mat-cell:first-of-type
    padding-left: 10px !important

.mat-datepicker-content, .mat-calendar
    width: auto !important
    height: auto !important

.mat-form-field-empty, .mat-form-field-label, .mat-form-field.mat-focused
    color: #999999 !important

.mat-datepicker-toggle
    background: url("./assets/icons/calendarIcon.svg") no-repeat !important

.mat-datepicker-toggle-default-icon
    display: none !important

.mat-primary, .mat-option, .mat-selected:not(.mat-option-disabled)
    color: inherit !important

.mat-calendar-body-selected 
    background-color: #e60000 !important

.mat-form-field
    padding-top: 10px !important

.orderSearchField
    padding-top: 0px !important
    .mat-form-field-infix
        padding-top: 11px !important
        padding-bottom: 11px !important

.mat-form-field-wrapper
    //margin-top: -8px !important

.applySpinner
    .mat-progress-spinner, circle, .mat-spinner, circle 
        stroke: #ffffff !important

.datePicker
    .maturityDate
        mat-datepicker-toggle
            position: inherit
            top: -5px !important
        top: 8px !important
        .mat-form-field-label, ng-star-inserted
                transform: translateY(-0.38125em) scale(0.75) perspective(100px) translateZ(0.001px) !important

.mat-step-label 
  display: inline-block !important
  white-space: break-spaces !important
  overflow: hidden !important
  text-overflow: ellipsis !important
  min-width: 50px !important
  vertical-align: middle !important
  width: 111px !important
  text-align: center !important

.mat-step-header
    flex-direction: column

.mat-step-icon-selected, .mat-step-icon-state-edit
  background-color: #007E92 !important

.mat-step-label-selected, .mat-step-label-active
  color: #007E92 !important

.tooltipWhite
  background: #FFFFFF !important
  padding: 10px !important
  box-radius: 20px !important
  box-shadow: 3px 2px 14px rgba(0, 0, 0, 0.15)
  position: absolute
  width: 166px
  height: 84px
  left: 12px
  top: 10px
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 10px
  line-height: 212.7%
  letter-spacing: 0.5px
  color: #333333 !important
