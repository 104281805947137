// colors 
$primary-color        : #E60000
$secondary-color      : #695643
$teriary-color        : #4a4d4e
$primaryaccent-color  : #007e92
$secondaryaccent-color: #007c92
$teriaryaccent-color  : #fecb00


$lightText: #ffffff
$darkText : #000000

$lightTableRow: #ffffff
$darkTableRow : #CCCCCC

$transparent: transparent
$Darkgray: #333333
$Gray       : #666666
$Red       : #e60000
$White      : #F9F9F9 
$Lightgray: #6A6573
$Faintgray: #CCCCCC 
$LowGray: #ebebeb 
$shadedGray: #f4f4f4 
$poweredOn: #428600 
$poweredOff: #bd0000 //font-size
$fontsize   : 16px

// border radius
$buttonRadius: 5px
$modalRadius : 10px

//padding
$buttonpadding: 18px 40px 15px 40px

//new colours
$darkNavBG: #25213a
$highlightedRed: #E60000
$backgroundGray: #f3f3f3
$topNavBG: #1A1029
$navTextColor: #B1B0BC
$highlightedTextColor: #FFFFFF
$highlightedTextHov: #2e2752


$newColor: #00B0CA
$acknowledgeColor: #9C2AA0
$confirmedColor: #005765
$rejected: #FF0000
$invoiced: #BD0000


$heading: #333333
$title: #333333
$subHeading: #333333