.graphBorder
    border: 1px black solid
    border-radius: 8px
    margin: 1em
    display: inline-block
    width: 90%

.graphBox
    background-color: #ebebeb
    width: 400px
    border-radius: 10px

@media screen and (max-width: 1200px)
    .graphBox
        width: 300px
