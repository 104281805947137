.mat-subheader
    padding: 16px 0px !important

mat-card.mb2
    margin-bottom: 2em

mat-divider.mt1
    margin-top: 1em

.mat-form-field-flex
    align-items: center !important
