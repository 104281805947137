//Modal
.modal
    border-radius: $modalRadius
    border: none
    position: fixed
    left: 25%
    top: 10%
    width: 50%
    background: white
    z-index: 100

.v-modal
    position: fixed
    top: 0
    bottom: 0
    left: 0
    width: 100%
    background: rgba(0, 0, 0, 0.5)
    padding-top: 4em
    z-index: 100

.modal-small
    top: 10%
    width: 40%
    left: 30%

.modal-med
    top: 10%
    width: 60%
    left: 20%

.modal-lg
    left: 10%
    top: 20%
    width: 80%
    min-height: 40%

.modal-spinner
    top: 40%
    width: 10%
    left: 45%

.app-modal-header
    vertical-align: middle
    margin: auto
    border-radius: $modalRadius $modalRadius 0 0

.app-modal-header p.modalHeading
    height: 60px
    margin: 0px
    display: table-cell
    vertical-align: middle
    padding: 0 30px
    font-size: 1.5em
    font-weight: 700

.app-modal-body
    background-color: $transparent
    height: fit-content
    max-height: 400px
    min-height: 100px
    color: $darkText
    margin: auto
    padding: 10px 0
    overflow-y: auto

.modal-no-form
    min-height: unset
    margin: 2em

.form, .modal-expansion-panel
    width: 90%
    margin: 20px auto

.app-modal-footer
    height: 50px
    color: $lightText
    padding: 10px 30px
    border-radius: 0 0 $modalRadius $modalRadius

    button
        top: -10px

.mat-form-field
    display: block !important
