@media screen and (max-width: 2400px)
    label.chooseFileBtn
        &.column
            left: 49.5%

.datePicker
    display: grid
    grid-gap: 10px
    grid-template-rows: auto
    grid-template-areas: 'datePicket .totalOrderValue toBeInvoiced'
    .datePickOrder
        height: 40px
        align-items: center
        justify-items: center
        padding-left: 30px
        padding-right: 30px
        & mat-form-field
            width: inherit
        .mat-form-field-infix
            padding: 1em 0
    .datePicket
        width: 45%
        height: 40px
        align-items: center
        justify-items: center
        padding-left: 30px
        padding-right: 30px
        & mat-form-field
            width: inherit
        .mat-form-field-infix
            padding: 1em 0
    .totalOrderValue, .toBeInvoiced
        display: grid
        align-items: center
        justify-items: end

.dashboardLayout
    display: grid
    grid-gap: 20px
    grid-template-rows: auto
    grid-template-areas: 'dashboardTitle dashboardTitle dashboardTitle dashboardTitle dashboardTitle dashboardTitle' 'orderSummary orderSummary orderSummary orderSummary orderSummary orderSummary' 'datePicker datePicker datePicker datePicker datePicker datePicker' 'totalOrders totalOrders totalOrders totalOrders totalOrders totalOrders' 'orderValues orderValues orderValues orderToInvoice orderToInvoice orderToInvoice' 'topPerforming topPerforming topPerforming topPerforming topPerforming topPerforming'

.totalOrdersCard
    display: grid
    grid-gap: 20px
    grid-auto-rows: 1fr
    grid-template-areas: 'totalOrders newOrdersPerRetailer newOrdersPerRetailer newOrdersPerRetailer'
    .newOrdersPerRetailer
        display: grid
        align-items: center
        justify-items: center
        padding-left: 30px
        padding-right: 30px

.myProfileCard
    display: grid
    grid-gap: 20px
    grid-template-areas: 'mpHeading mpHeading mpHeading mpHeading mpHeading' 'mpBusinessInfo mpBusinessInfo mpBusinessInfo mpBusinessInfo mpBusinessInfo' 'mpNotifications mpNotifications mpNotifications mpPassword mpPassword' 'mpUsers mpUsers mpUsers mpUsers mpUsers' 'mpActivityLogs mpActivityLogs mpActivityLogs mpActivityLogs mpActivityLogs'
    .mpHeading, .mpBusinessInfo, .mpNotifications, .mpLastPerformed, .mpUsers, .mpActivityLogs
        padding: 30px
        .mpHeading
            padding-top: 30px !important
            padding-left: 0px !important
            padding-right: 0px !important
            padding-bottom: 30px !important
            height: min-content
    .mpPassword
        padding: 30px
        height: inherit

.rtLinkedPartners
    display: grid
    grid-gap: 20px
    padding: 30px !important
    grid-template-areas: 'retailerList retailerList retailerList tradingProcess'
    .rtLinkedPartners, .rtUsers
        padding: 30px !important
        height: inherit

.editBusinessLayout
    display: grid
    grid-gap: 20px
    grid-template-areas: 'busiinessAddress busiinessAddress busiinessNoC busiinessCellSoC' 'busiinessAddress busiinessAddress busiinessEmail busiinessEmail' 'busiinessAddress busiinessAddress busiinessTelNumber busiinessTelNumber ' 'busiinessAddress busiinessAddress busiinessFaxNumber busiinessFaxNumber' 'busiinessAddress busiinessAddress ..'
    .busiinessEmail, busiinessTelNumber, busiinessFaxNumber
        height: min-content

@media screen and (max-width: 900px)

    .rtLinkedPartners
        display: grid
        grid-gap: 20px

    .myProfileCard
        display: grid
        grid-gap: 20px
        grid-template-areas: 'mpHeading mpHeading mpHeading mpHeading' 'mpBusinessInfo mpBusinessInfo mpBusinessInfo mpBusinessInfo' 'mpNotifications mpNotifications mpNotifications mpNotifications' 'mpPassword mpPassword mpPassword mpPassword' 'mpLastPerformed mpLastPerformed mpLastPerformed mpLastPerformed' 'mpUsers mpUsers mpUsers mpUsers ' 'mpActivityLogs mpActivityLogs mpActivityLogs mpActivityLogs'

    .bl-lt
        border-left: none !important
        border-top: 3px solid rgba(0, 0, 0, 0.52) !important
        padding-top: 30px

    .totalOrdersCard
        display: grid
        grid-gap: 20px
        grid-auto-rows: auto
        grid-template-areas: 'totalOrders totalOrders totalOrders totalOrders' 'newOrdersPerRetailer newOrdersPerRetailer newOrdersPerRetailer newOrdersPerRetailer '
        .newOrdersPerRetailer
            padding-top: 20px

    .dashboardLayout
        display: grid
        grid-gap: 20px
        grid-template-rows: auto
        grid-template-areas: 'dashboardTitle dashboardTitle dashboardTitle dashboardTitle' 'orderSummary orderSummary orderSummary orderSummary' 'datePicker datePicker datePicker datePicker' 'totalOrders totalOrders totalOrders totalOrders' 'orderValues orderValues orderValues orderToInvoice' 'insights insights insights insights'

    .datePicker
        display: grid
        grid-gap: 10px
        grid-template-rows: auto
        grid-template-areas: 'datePicket ..' 'totalOrderValue totalOrderValue totalOrderValue' 'toBeInvoiced toBeInvoiced toBeInvoiced'
        .datePicket
            width: 40%
            align-items: center
            justify-items: center
        .totalOrderValue, .toBeInvoiced
            display: grid
            align-items: center
            justify-items: start
            padding-top: 20px
        //  Direction

    .fx-sm-column
        flex-direction: column

@media screen and (max-width: 1367px)
    label.chooseFileBtn
        &.column
            left: 49.5%

@media screen and (max-height: 800px)
    .mat-tab-body.mat-tab-body-active
        //height: 70vh

@media screen and (max-height: 700px)
    .mat-tab-body.mat-tab-body-active
        //height: 70vh

.datePicket
    grid-area: datePicket
.totalOrderValue
    grid-area: totalOrderValue
.toBeInvoiced
    grid-area: toBeInvoiced

.dashboardTitle
    grid-area: dashboardTitle
.datePicker
    grid-area: datePicker
.orderSummary
    grid-area: orderSummary
.totalOrders
    grid-area: totalOrders
.topPerforming
    grid-area: topPerforming
.orderValues
    grid-area: orderValues
.orderToInvoice
    grid-area: orderToInvoice
.insights
    grid-area: insights

.myProfileCard
    grid-area: myProfileCard
.mpHeading
    grid-area: mpHeading
.mpBusinessInfo
    grid-area: mpBusinessInfo
.mpNotifications
    grid-area: mpNotifications
.mpPassword
    grid-area: mpPassword
.mpLastPerformed
    grid-area: mpLastPerformed
.mpUsers
    grid-area: mpUsers
.mpActivityLogs
    grid-area: mpActivityLogs
.rtInformation
    grid-area: rtInformation
.rtLinkedPartners
    grid-area: rtLinkedPartners
.rtTradingProcess
    grid-area: rtTradingProcess
.rtUsers
    grid-area: rtUsers
.retailerList
    grid-area: retailerList
.tradingProcess
    grid-area: tradingProcess

.totalOrdersCard
    grid-area: totalOrdersCard
.totalOrders
    grid-area: totalOrders
.newOrdersPerRetailer
    grid-area: newOrdersPerRetailer

.editBusinessLayout
    grid-area: editBusinessLayout
.busiinessAddress
    grid-area: busiinessAddress
.busiinessEmail
    grid-area: busiinessEmail
.busiinessNoC
    grid-area: busiinessNoC
.busiinessCellSoC
    grid-area: busiinessCellSoC
.busiinessTelNumber
    grid-area: busiinessTelNumber
.busiinessFaxNumber
    grid-area: busiinessFaxNumber
